import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { theme, mixins, media, Section } from "../styles";
import {IconExternal } from './icons';

const { colors, fontSizes, fonts } = theme;

const ProjectsContainer = styled(Section)`
  ${mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
`;
const ProjectsTitle = styled.h4`
  margin: 0 auto 50px;
  font-size: ${fontSizes.h3};
  ${media.tablet`font-size: 24px;`};
  a {
    display: block;
  }
`;
const ProjectsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    position: relative;
`;
const ProjectInner = styled.div`
  ${mixins.flexBetween};
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 2rem 1.75rem;
  height: 100%;
  border-radius: ${theme.borderRadius};
  transition: ${theme.transition};
  background-color: ${colors.white};
`;
const Project = styled.div`
  transition: ${theme.transition};
  cursor: default;
`;
const ProjectName = styled.h3`
  margin: 0 0 10px;
  color: ${colors.darkGrey};
  font-size: ${fontSizes.xxlarge};
`;
const ProjectDescription = styled.div`
  font-size: ${fontSizes.large};
  color: ${colors.mediumGrey};
  a {
    ${mixins.inlineLink};
  }
`;
const Links = styled.div`
  ${mixins.externalIconLink};
`;

const Projects = ({ data }) => {

  const projectsToShow = data;

  return (
    <ProjectsContainer id="work">
      <ProjectsGrid>
        {projectsToShow &&
        projectsToShow.map(({ node }, i) => {
          const { frontmatter, html } = node;
          const {external, title } = frontmatter;
          return (
            <Project key={i}>
              <ProjectInner>
                <header>
                  <ProjectName>{title}</ProjectName>
                  <ProjectDescription dangerouslySetInnerHTML={{ __html: html }}/>
                </header>

                <footer>
                    <Links>
                      {external && (
                        <a
                          href={external}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          aria-label="External Link">
                          <IconExternal />
                        </a>
                      )}
                    </Links>
                </footer>
              </ProjectInner>
            </Project>
          );
        })}
      </ProjectsGrid>

    </ProjectsContainer>
  );
};

Projects.propTypes = {
  data: PropTypes.array.isRequired
};

export default Projects;