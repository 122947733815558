import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollReveal from 'scrollreveal';
import { srConfig, email } from '../config';
import styled from 'styled-components';
import {mixins, media, Section, theme } from '../styles';

const ContactContainer = styled(Section)`
  text-align: center;
  color: ${theme.colors.mediumGrey};
  max-width: 600px;
  margin: 0 auto 100px;
`;

const Title = styled.h4`
  margin: 0 0 20px;
  font-size: 60px;
  ${media.desktop`font-size: 50px;`};
  ${media.tablet`font-size: 40px;`};
`;
const EmailLink = styled.a`
  ${mixins.bigButton};
  margin-top: 50px;
`;

class Contact extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
  };

  componentDidMount() {
    ScrollReveal().reveal(this.contact, srConfig());
  }

  render() {
    const { data } = this.props;
    const { frontmatter, html } = data[0].node;
    const { title } = frontmatter;

    return (
      <ContactContainer id="contact" ref={el => (this.contact = el)}>

        <Title>{title}</Title>

        <div dangerouslySetInnerHTML={{ __html: html }} />

        <EmailLink href={`mailto:${email}`} target="_blank" rel="nofollow noopener noreferrer">
          Email
        </EmailLink>
      </ContactContainer>
    );
  }
}

export default Contact;
