import React, { Component } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import ScrollReveal from "scrollreveal";
import { srConfig } from "../config";
import styled from "styled-components";
import { theme, mixins, media, Section, Heading } from "../styles";
import resume from "../../static/Marko's Resume.pdf";

const { colors, fontSizes } = theme;

const AboutContainer = styled(Section)`
  position: relative;
`;
const FlexContainer = styled.div`
  ${mixins.flexBetween};
  align-items: flex-start;
  ${media.tablet`display: block;`};
`;
const ContentContainer = styled.div`
  width: 60%;
  max-width: 480px;
  ${media.tablet`width: 100%;`};
`;
const AboutText = styled.div`
  color: ${colors.mediumGrey};
  a {
    ${mixins.inlineLink};
  }
`;
const PicContainer = styled.div`
  position: relative;
  width: 40%;
  ${media.tablet`width: 100%; margin-bottom: 30px`};
  ${media.phablet`width: 100%; margin: 30px`};
`;
const Avatar = styled(Img)`
  position: relative;
  border-radius: ${theme.borderRadius};
`;
const AvatarContainer = styled.div`
  width: 100%;
  position: relative;
  border-radius: ${theme.borderRadius};
  background-color: ${colors.lightBlue};
  margin-left: -20px;
`;

const ResumeLink = styled.a`
  ${mixins.bigButton};
`;

class About extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired
  };

  componentDidMount() {
    ScrollReveal().reveal(this.about, srConfig());
  }

  render() {
    const { data } = this.props;
    const { frontmatter, html } = data[0].node;
    const { title, avatar } = frontmatter;

    return (
      <AboutContainer id="about" ref={el => (this.about = el)}>
        <Heading>{title}</Heading>

        <FlexContainer>

          <PicContainer>
            <AvatarContainer>
              <Avatar fluid={avatar.childImageSharp.fluid} alt="Avatar"/>
            </AvatarContainer>
          </PicContainer>

          <ContentContainer>
            <AboutText dangerouslySetInnerHTML={{ __html: html }}/>
            {/* <ResumeLink href={resume} target="_blank" rel="nofollow noopener noreferrer">
              Resume
            </ResumeLink> */}
          </ContentContainer>

        </FlexContainer>
      </AboutContainer>
    );
  }
}

export default About;
