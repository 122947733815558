import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import ScrollReveal from 'scrollreveal';
import { srConfig } from '../config';
import {IconExternal } from './icons';
import styled from 'styled-components';
import { theme, mixins, media, Section, Heading } from '../styles';
const { colors, fontSizes, fonts } = theme;

const FeaturedContainer = styled(Section)`
  ${mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  
`;
const FeaturedGrid = styled.div`
  position: relative;
`;
const ContentContainer = styled.div`
  position: relative;
  
  // Required for title and svg links to work on mobile
  z-index: 2;
  
  grid-column: 1 / 6;
  grid-row: 1 / -1;
  ${media.thone`
    grid-column: 1 / -1;
    padding: 40px 40px 30px;
  `};
  ${media.phablet`padding: 30px 25px 20px;`};
`;

const ProjectName = styled.h5`
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 20px;
  color: ${colors.darkGrey};
  ${media.tablet`font-size: 24px;`};
  a {
    ${media.tablet`display: block;`};
  }
`;
const ProjectDescription = styled.div`
  background-color: ${colors.white};
  color: ${colors.mediumGrey};
  padding: 0px;
  border-radius: ${theme.borderRadius};
  font-size: ${fontSizes.large};
  ${media.thone`
    max-width: 100%;
    background-color: transparent;
    padding: 20px 0;
  `};
  p {
    margin: 0;
  }
  a {
    ${mixins.inlineLink};
  }
`;
const TechList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0 10px;
  li {
    font-family: ${fonts.Nunito};
    font-size: ${fontSizes.smallish};
    color: ${colors.mediumGrey};
    margin-right: ${theme.margin};
    margin-bottom: 7px;
    white-space: nowrap;
    &:last-of-type {
      margin-right: 0;
    }
    ${media.thone`
      color: ${colors.darkGrey};
      font-weight: 500
      margin-right: 10px;
    `};
  }
`;
const Links = styled.div`
  ${mixins.externalIconLink};
`;
const FeaturedImg = styled(Img)`
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  border-radius: ${theme.borderRadius};
  position: relative;
  ${media.tablet`
    object-fit: cover;
    width: auto;
    height: 100%;
  `};
`;
const ImgContainer = styled.div`
  position: relative;
  // z-index: 1;
  // border-radius: ${theme.borderRadius};
  // border: 1px solid ${colors.darkGrey};
  grid-column: 7 / -1;
  grid-row: 1 / -1;
  
  // Transition causes image to appear saturated before it loads
  // transition: ${theme.transition};
  
  ${media.tablet`height: 100%;`};
  ${media.thone`
    grid-column: 1 / -1;
    opacity: 0.1;
  `};
`;
const Project = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  margin-bottom: 100px;
  ${media.thone`margin-bottom: 70px;`};
  &:last-of-type {
    margin-bottom: 0;
  }
  &:nth-of-type(odd) {
    ${ContentContainer} {
      grid-column: 9 / -1;
      text-align: left;
      ${media.thone`
        grid-column: 1 / -1;
        padding: 40px 40px 30px;
      `};
      ${media.phablet`padding: 30px 25px 20px;`};
    }
    ${ImgContainer} {
      grid-column: 1 / 8;
      ${media.tablet`height: 100%;`};
      ${media.thone`
        grid-column: 1 / -1;
        opacity: 0.25;
      `};
    }
  }
`;

class Featured extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.revealRefs = [];
  }

  componentDidMount() {
    ScrollReveal().reveal(this.featured, srConfig());
    this.revealRefs.forEach(ref => ScrollReveal().reveal(ref, srConfig()));
  }

  render() {
    const { data } = this.props;
    const featuredProjects = data.filter(({ node }) => node.frontmatter.show === 'true');

    return (
      <FeaturedContainer id="projects">
        <Heading ref={el => (this.featured = el)}>Projects</Heading>
        <FeaturedGrid>
          {featuredProjects &&
            featuredProjects.map(({ node }, i) => {
              const { frontmatter, html } = node;
              const { external, title, tech, cover } = frontmatter;

              return (
                <Project key={i} ref={el => (this.revealRefs[i] = el)}>
                  <ContentContainer>
                    <ProjectName>
                      {external ? (
                        <a
                          href={external}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          aria-label="External Link">
                          {title}
                        </a>
                      ) : (
                        title
                      )}
                    </ProjectName>
                    <ProjectDescription dangerouslySetInnerHTML={{ __html: html }} />
                    {tech && (
                      <TechList>
                        {tech.map((tech, i) => (
                          <li key={i}>{tech}</li>
                        ))}
                      </TechList>
                    )}
                    <Links>
                      {external && (
                        <a
                          href={external}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          aria-label="External Link">
                          <IconExternal />
                        </a>
                      )}
                    </Links>
                  </ContentContainer>

                  <ImgContainer>
                    <FeaturedImg fluid={cover.childImageSharp.fluid} />
                  </ImgContainer>
                </Project>
              );
            })}
        </FeaturedGrid>
      </FeaturedContainer>
    );
  }
}

export default Featured;
